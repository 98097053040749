
.DiaSemanaRotina, .TituloAcoesInteligentes{
    color: #36f!important;
    font-weight: 700!important;
    margin-right: 1rem!important;
}

.TextoApoioDiaSemanaRotina, .DiaAcaoInteligente{
    color: #565656!important;
    font-size: 15px!important;
}

