.dialogGlobalChamadaVideo {
position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 93vh;
  }

.dialogminiVideo {
position: absolute;
    bottom: 100px;
    right: 10px;
    width: 150px;
    height: 200px;
    z-index: 99999;
  }



.bg-video {
  background-image: url('./img/background-chamada-audio.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden!important;
}

.FaceTimeDiv{
  width: 100vw;
  height: 100vh;
  overflow: hidden!important;
  padding: 30px;
}

.textobranco {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}






.menuFixedActionsCallVideo{
    background: rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(10px);
    border-top: 2px solid rgba(255, 255, 255, .2);
}

.iconsActionChamadaVideo{
    width: 30px;
    height: 30px;
}

.ImageAvatar{
    border-radius: 100%;
}

.btnEncerrarChamadaVideo{
   position: relative;
   top: -40px;
}

#btnEncerrarChamadaVideo{
    background-color: #FF5630;
}

.imgSairChamada{
    margin-bottom: 20px;
    margin-top: -60px;
    z-index: 999999999;
    object-fit: cover;
    animation: animName 2s infinite;
}

.MuiDrawer-paperAnchorBottom{
    overflow: visible!important;
}

@keyframes animName {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: rotate(15deg);
    }
    100% {
      transform: translateX(0);
    }
  }
 



