.BotaoCTAVerPerfil {
    height: 3rem!important;
    margin-bottom: .5rem!important;
}

.CTAVerPerfilGrid {
    display: flex;
    flex-direction: column;
}

.cardAvaliacoesVerPerfil{
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
}

.cardConversasSobre{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
}

.cardConversasSobre h3{
    margin: 0px!important;
}

.NomeLocaleDepoimento {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    margin-left: 1rem;
}

.NomeLocaleDepoimento h3 {  
    margin: 0px!important;
    text-align: left;
}

.NomeLocaleDepoimento p {  
    text-align: left;
}

.LocalDepoimento{
    display:inline-flex ;
}

@media (min-width: 768px){
    .CTAVerPerfilGrid button {
        width: 100%;
    }
    .GlobalCardsAvaliacoes{
        margin-left: 20px!important;
    }
}