.inputmasktelefone{
    border-radius: 8px;
    font-size: 16px;
    height: 55px;
    padding: 16.5px 14px;
    border: 1px solid rgba(145, 158, 171, 0.32);
}
.inputmasktelefone:focus{
    border-color: #000;
}

.inputmasktelefone:hover{
    border-color: #000;
}
.inputmasktelefone::placeholder {
    padding-left: 10px;
}

.inputmasktelefoneerro{
    height: 55px;
    padding: 5px;
    border-radius: 12px;
    border: 1px solid #FF5630!important;
    font-size: 16px;
}
.inputmasktelefoneerro::placeholder {
    color: #FF5630!important;
    padding-left: 10px;
}
.inputmasktelefoneerro:focus{
    border: 1px solid #FF5630!important;
}
.inputmasktelefoneerro:hover{
    border: 1px solid #FF5630!important;
}
.FormHelperTexttelefone{
   margin-left: 15px!important;
}

.TextoApoioOculto{
    display: none;
}

.TextoApoio{
    background-color: #fff;
    font-weight: 400;
    font-size: 13px!important;
    padding: 0px 5px;
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    margin-left: 10px!important;
    margin-top: -10px!important;
}