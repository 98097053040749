.boxTabsPacotes .MuiTabs-flexContainerVertical button:last-child{
    margin-right: 40px;
}

.boxTabsPacotes .MuiTabs-flexContainerVertical button{
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 8px 10px;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12)!important;
    border: 1px solid rgba(145, 158, 171, 0.12);
    transition-duration: 0.5s;
    transform: scale(0.9);
}

.boxTabsPacotes .MuiTabs-flexContainerVertical button.Mui-selected{
    background: linear-gradient(135deg, #078dee, #0351ab);
    color: #fff;
    transform: scale(1);
}

.boxTabsPacotes .MuiTabs-flexContainerVertical button:not(.Mui-selected):hover{
    transform: scale(0.95);
}

.boxTabsPacotes .MuiTabs-flexContainerVertical button:not(.Mui-selected){
    background-color: #fff;
}

.boxTabsPacotes #vertical-tabpanel-0 > div,
.boxTabsPacotes #vertical-tabpanel-1 > div,
.boxTabsPacotes #vertical-tabpanel-2 > div,
.boxTabsPacotes #vertical-tabpanel-3 > div,
.boxTabsPacotes #vertical-tabpanel-4 > div {
    padding: 0px 24px;
}