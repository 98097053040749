.cardAtendente{
    /* Background/Light/Paper */
    background: #FFFFFF;
    /* Shadows/Light/Card */
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 15px;
    margin: 15px 5px
}

.cardAtendente .css-hxrfoi-MuiStack-root {
    width: 100%;
    display: inline-flex;
    align-items: flex-start;
}

.IndicadorTipoProfissionalAtendente{
    background: #3366FF;
    border-radius: 8px;
    padding: 2px 9px;
    color: #fff;
    margin-bottom: 5px;
}

.ItemAtendentePerfil span.MuiBadge-root.css-1c32n2y-MuiBadge-root {
    height: 45px;
}

.ItemAtendentePerfil, .AssuntosAtendente{
    display: inline-flex;
}

.NomeETemasAtendente{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 25px;
}

.NomeETemasAtendente .NomeDoAtendente{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #45515B;
}

.ValorETempoAtendimento {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.FotoPerfilAtendente{
    height: min-content;
}

.AvaliacaoAtendente{
    margin-top: 10px;
}

.AvaliacaoAtendimento {
    display: inline-flex;
    align-content: flex-end;
    align-items: flex-end;
}

.AvaliacaoAtendenteTexto{
    font-style: normal;
    font-weight: 400;
    font-size: 12px!important;
    line-height: 22px;
    color: #637381;
    display: inline-flex;
    margin-left: 10px!important;
}

.cardAtendente .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1w11epi-MuiPaper-root {
    padding: 0;
}

.AvaliacaoAtendimento .css-17ceore-MuiSvgIcon-root{
    width: 0.75em;
    height: 0.75em;
}

.ValorETempoAtendimento .ValorAtendimento{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #45515B;
}

.ValorETempoAtendimento .TempoAtendimento {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #637381;

}

.AssuntosAtendente .Assunto{
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #637381;
    padding: 0px 5px;
    border-left: 1px solid #637381;
}

.BotoesAcao{
    margin-top: 20px!important;
}

.BtnAzulAtendente{
    background: #3366FF!important;
    border-radius: 8px!important;
    color: #fff!important;
    padding: 6px!important;
    width: 100%;
}

.BtnBrancoAtendente{
    border: 1px solid rgba(145, 158, 171, 0.32)!important;
    color: #45515B!important;
    border-radius: 8px;
    padding: 6px!important;
    width: 100%;
}