.geralAgendamentoAtendenteOnOff{
    height: 100%;
}



.CardConfirmacaoHorario{
    width: 100%!important;
    padding: 15px!important;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);

}

.CardConfirmacaoHorario p{
    color: #212B36;
    font-family: Public Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}

.cardAgendamentoAtendente{
    max-width: 100%!important;
}

.select-day{
    background-color: #00A000!important;
    color: #fff!important;
    border-radius: 25px;
    position: relative;
    height: 48px;
    padding-bottom: 5px;
    z-index: 0!important;
    border: none!important;
    display: flex;
    justify-content: center;
    align-content: flex-end;
    align-items: flex-end;
    top: -25px;
}



.CardConfirmacaoHorario .chipConfirmacaoHorario{
    border-radius: 8px;
    border: 1px solid var(--components-button-outlined, rgba(145, 158, 171, 0.32));
    color: #212B36;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}

.HorariosSugeridosTitulo{
    color:  #637381!important;
    font-family: Public Sans!important;
    font-size: 12px!important;
    font-style: normal!important;
    font-weight: 700!important;
    line-height: 18px!important;
    text-transform: uppercase!important;
    text-align: left!important;
}

.topoAgendamento{
    position: absolute;
    top: 1rem;
}

.sugestaoDoAtendenteHorario{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sugestaoDoAtendenteHorarioTitulo{
    color: #637381!important;
    text-align: left!important;
    font-family: Public Sans!important;
    font-size: 16px!important;
    font-style: normal!important;
    font-weight: 400!important;
    line-height: 24px!important;
}

.sugestaoDoAtendenteHorario p{
    color:  #212B36!important;
    font-size: 20px!important;
    font-style: normal!important;
    font-weight: 700!important;
    line-height: 36px!important;
}

.TopoAgendamentoProfile{
    padding: 2rem;
    display: flex;
    align-items: center;
}

.FotoeNomeAtendenteAgendamento{
    display: flex;
    align-items: center;
}

.btnAgendamento{
    margin: .4rem 0!important;
    text-transform: none!important;
}

.opcoesHorarios{
    max-width: 100%;
}


.tabsHorarios{
    max-width: 90vw;
    margin: 0!important;
    padding: 0!important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    overflow-x: scroll!important;
}

.buttonGroupHorarios button{
    border-radius: 8px!important;
    border: 1px solid rgba(145, 158, 171, 0.32)!important;
    margin: 0px 10px!important;
    font-weight: 700!important;
    padding: 11px!important;
}

.HorariosAgendamentoTabs  .MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableX{
    overflow-x: scroll!important;
}

.tabsHorariosConfirmacao, .BoxHorariosDisponiveisAgendamento{
    width: 100%!important;
}

.HorariosAgendamentoTabs button{
    border-radius: 8px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    width: 4rem!important;
    margin-right: 0.8rem!important;
}

.HorariosAgendamentoTabs span{
    display: none;
}

.HorariosAgendamentoTabs button.Mui-selected{
    background-color: #36f;
    color: #fff!important;
}

.horariosAgendamento, .weekly-calendar{
    margin: 15px 0;
    padding: 1.4rem;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);
}

.MesesdoAno{
    text-transform: capitalize!important;
}

.navigation{
    display: flex;
    margin-bottom: 10px;
}

.week, .weeksName{
    display: flex;
    justify-content: space-between;   
    height: 20px
}


.weekName, .day{
    text-align: center;
    width: 25px;
    z-index: 1;
}

.weekName{
    color: #bababa!important;
    font-size: 12px;
}

.current-day{
    background-color: #36f;
    color: #fff!important;
    border-radius: 25px;
    position: relative;
    height: 48px;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-content: flex-end;
    align-items: flex-end;
    top: -25px;
}

.detalhesAgendamentoGlobal{
    padding: 0 1rem;
}

.AgendamentosAtendente .CardAgendamentoGeral {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    margin: .3rem 0!important;
}

.FotoeNomeCardAgendamento {
    display: flex;
    align-items: center;
}

.NomeeStatusCardAgendamento{
    margin-left: 1rem;
}

.NomeeStatusCardAgendamento .realizado {
    color: #36B37E;
}

.NomeeStatusCardAgendamento .agendado {
    color: #FFAB00;
}

.NomeeStatusCardAgendamento .naoConfirmado {
    color: #00B8D9;
}

.NomeeStatusCardAgendamento .cancelado {
    color: #FF5630;
}

.calendarioCardAgendamento.realizado{
    background-color: rgba(54, 179, 126, 0.16);
    
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendarioCardAgendamento.realizado button{
    color: #36B37E;
}

.calendarioCardAgendamento.agendado{
    background-color: rgba(255, 171, 0, 0.16);
    
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendarioCardAgendamento.agendado button{
    color: #FFAB00;
}

.calendarioCardAgendamento.naoConfirmado{
    background-color: rgba(0, 184, 217, 0.16);
    
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendarioCardAgendamento.naoConfirmado button{
    color: #00B8D9;
}

.calendarioCardAgendamento.cancelado{
    background-color: rgba(255, 86, 48, 0.16);
   
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendarioCardAgendamento.cancelado button{
    color: #FF5630;
}


.CardInfoAtendimentoGeral,
.CardAvaliacaoInfoAgendamento, 
.RemarcarAtendimentoDialogGeral,
.CardRemarcarAtendimentoGeral{
    padding: 1.5rem;
    margin: 2rem 0px!important;
}

.CardAvaliacaoInfoAgendamento .AvaliacaoTexto,
.avisoRemarcarAtendimento{
    color: #979797!important;
    font-family: Public Sans!important;
    font-size: 14px!important;
    font-style: italic!important;
    font-weight: 400!important;
    line-height: 22px!important;
}

.avisoRemarcarAtendimento{
    text-align: center;
}

.dadosCardInfoAtendimento{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    color: #637381!important;
}

.CardRemarcarAtendimentoGeral input[type="date"],  .CardRemarcarAtendimentoGeral input[type="time"]{
    background-color: #0080ff;
    padding: 15px;
    font-family: "Roboto Mono",monospace;
    color: #ffffff;
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 5px;
    margin: 10px 0;
}
.CardRemarcarAtendimentoGeral ::-webkit-calendar-picker-indicator{
    background-color: #0080ff;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}

