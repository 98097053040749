.CardRegras{
    padding: 20px 15px;
    border-radius: 20px!important;
background: linear-gradient(145deg, #fcfcfc, #ffffff)!important;
box-shadow:  10px 10px 20px #e0e0e0,
             -10px -10px 20px #ffffff!important;
}


.CardRegras p{
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 28px!important;
color: #212B36;
}