.cardEditarPerfil{
    background: #FFFFFF!important;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12)!important;
    border-radius: 16px!important;
    padding: 20px 15px!important;
    margin: 15px 0px!important;
}

.Capas{
    display: flex!important;
}

.FotoEscolhaPerfil{
    display: inline-flex!important;
    width: 100%!important;
    align-items: center!important;
    justify-content: center!important;
}

.BotaoEscolhaSuaFoto{
    margin-left: 10px!important;
    font-size: 1rem;
    font-weight: 700!important;
    font-size: 20px!important;
}

img.FotosCapa {
    height: 6rem;
    object-fit: cover;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12)!important;
    border-radius: 16px!important;
}

.TituloCardsPersonalizarPerfil{
    font-weight: 700!important;
    font-size: 20px!important;
    color: #212B36!important;
    margin-bottom: 15px!important;
}

@media (max-width: 767px){
    img.FotosCapa {
        height: 6rem;
        object-fit: cover;
    }
}

@media (min-width: 1440px){
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.OpcoesFotosCapa.css-1w11epi-MuiPaper-root {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
