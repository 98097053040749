.dialogGlobalChamadaAudio{
    background-image: url('./img/background-chamada-audio.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.cardsaudio{
    display: flex;
    width: 70%;
    justify-content: space-between;
}

.imgaudioStage{
margin-top: 10px;
}
.textoStage{
color: #FFF;
}



.menuFixedActionsCallAudio{
    background: rgba(0, 0, 0, .1) !important;
    backdrop-filter: blur(10px);
    border-top: 2px solid rgba(255, 255, 255, .2);
}

.iconsActionChamadaAudio{
    width: 30px;
    height: 30px;
}

.ImageAvatar{
    border-radius: 100%;
}

.btnEncerrarChamadaAudio{
   position: relative;
   top: -40px;
}

#btnEncerrarChamadaAudio{
    background-color: #FF5630;
}

.imgSairChamada{
    margin-bottom: 20px;
    margin-top: -60px;
    z-index: 999999999;
    object-fit: cover;
    animation: animName 2s infinite;
}

.MuiDrawer-paperAnchorBottom{
    overflow: visible!important;
}

@keyframes animName {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: rotate(15deg);
    }
    100% {
      transform: translateX(0);
    }
  }
 



