.CardResumoPerdidas{
    background-color: #FFE9D5!important;
    color: #7A0916!important;
}

.CardResumoPerdidas .apexcharts-series path{
    stroke: #7A0916!important;
}

.CardResumoPerdidas  .apexcharts-datalabels-group text{
    fill: #7A0916!important;
}




.CardResumoAtendidas{
    background-color: #D8FBDE!important;
    color: #0A5554!important;
}

.CardResumoAtendidas .apexcharts-series path{
    stroke: #0A5554!important;
}

.CardResumoAtendidas .apexcharts-datalabels-group text{
    fill: #0A5554!important;
}