.ChamadaRecebidaDialogCard{
    padding: 60px!important;
    background-image: url('img/bg-dialog-chamadas.png');
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
    border-radius: 16px!important;
}


.ChamadaRecebidaDialogCard .css-1nky8vc{
    background: transparent;
}

@media (max-width: 767px){
    .ChamadaRecebidaDialogCard{
        padding: 30px!important;
    }
}

.ChamadaRecusadaDialogCard{
    padding: 60px!important;
    background-color: #FF5630!important;
}

@media (max-width: 767px){
    .ChamadaRecusadaDialogCard{
        padding: 30px!important;
    }
}

.FotoAtendenteChamadaRecebidaDialog, .FotoAtendenteChamadaRecusadaDialog{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin: 10px auto;
}

.RecusarChamada {
    background-color: #FF5630!important;
    width: 60px;
    height: 60px;
}

.AceitarChamada{
    background-color: #36B37E!important;
    width: 60px;
    height: 60px;
}

.RecusarChamada:hover {
    background-color: #db340f!important;
}

.AceitarChamada:hover{
    background-color: #259d6b!important;
}


