
.DashboardFirst{
    padding: 10px 20px;
}

.ButtonActionAtendimento{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
}

.ButtonActionAtendimento button{
    width: 48%;
}
.hide{
    display: none;
}
#AudioMaterialApoio{
    width: 100%;
}

.FotoNomeAssuntosLembrete{
    display: flex;
}

@media (max-width: 500px){
    .ButtonActionAtendimento button{
        padding: 6px 0!important;
    }
}

.itensAtendentesDash {
    background-color: transparent!important;
}
  

.BreadCrumbsListagemMaterial{
    margin: 0px 8px;
}

.BemVindoTituloDashFirst{
    font-weight: 700!important;
    font-size: 20px!important;
    color: #212B36!important;
    margin-bottom: 15px!important;
}

.NomeCardTopoPrincipalDashFirst{
    color: #fff;
    margin-top: 5px!important;
    font-weight: 700!important;
    font-size: 20px;
}

.NomeCardTopoPrincipalDashFirstDesktop{
    color: #fff;
    margin-top: 5px!important;
    font-weight: 700!important;
    font-size: 20px;
    margin-left: 15px!important;
}

.CardFinanceiroDashboard, .CardMateriaisApoioDashboard, .CardAvaliacoesDashboard{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin: 15px 0px;
}

.AgendamentosCard{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin: 15px;
}

.CardNotificacaoSaladeEspera{
    background: #D6E4FF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin: 15px 0;
}

.FotoNomeAssuntoCardSaladeEspera{
    display: inline-flex;
    align-items: center;
    align-content: center;
}

.ItensCardNotificacaoSaladeEspera svg.MuiBox-root.css-1t9pz9x.iconify.iconify--eva {
    margin-right: 8px;
}

.FotoNomeAssuntoCardSaladeEspera p{
    margin-left: 8px;
}

.ItensCardNotificacaoSaladeEspera{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0;
}

.Titulo_CardNotificacaoSaladeEspera{
    font-size: 18px!important;
    font-family: Public Sans!important;
    font-weight: 700!important;
    color: #091A7A!important;
}

.Subtitulo_CardNotificacaoSaladeEspera{
    font-size: 14px!important;
    font-family: Public Sans!important;
    font-weight: 400!important;
    color: #091A7A!important;
}

.NotificacaoNomeCardSaladeEspera{
    font-size: 14px!important;
    font-weight: 600!important;
    color: #091A7A!important;
}

.NotificacaoAssuntoCardSaladeEspera{
    font-size: 12px!important;
    font-weight: 400!important;
    color: #091A7A!important;
}

.TemporizadorCardNotificacaoSaladeEspera{
    margin-top: 10px;
    display: inline-flex;
    width: 100%;
}

.TempoTemporizadorCardNotificacaoSaladeEspera{
    font-size: 18px!important;
    color: #212B36!important;
    font-weight: 700!important;
    margin-right: 10px!important;
}

.BtnIcon_CardSaladeEspera{
    background-color: rgba(51, 102, 255, 0.16);
    border-radius: 12px;
    padding: 6px;
    height: 40px;
    width: 40px;
    color: #3366FF;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}


.time_CardSaladeEspera{
font-size: 12px;
    width: 120px;
    color: #3366FF;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}







.CardLembretesNotificacaoCard{
    background: #fff;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin: 15px 0;
}

.ItensCardLembretesNotificacaoCard{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0;
}

.ItensCardLembretesNotificacaoCard .Titulo_CardLembretesNotificacaoCard{
    font-size: 18px!important;
    font-family: Public Sans!important;
    font-weight: 700!important;
    color: #212B36!important;
}

.ItensCardLembretesNotificacaoCard .Subtitulo_CardLembretesNotificacaoCard{
    font-size: 14px!important;
    font-family: Public Sans!important;
    font-weight: 400!important;
    color: #212B36!important;
}

.ItensCardLembretesNotificacaoCard .NotificacaoNomeCardNotificacaoCard{
    font-size: 14px!important;
    font-weight: 600!important;
    color: #212B36!important;
}

.ItensCardLembretesNotificacaoCard .NotificacaoAssuntoCardNotificacaoCard{
    font-size: 12px!important;
    font-weight: 400!important;
    color: #212B36!important;
}

.ItensCardLembretesNotificacaoCard .BtnIcon_CardNotificacaoCard{
    background-color: rgba(0, 184, 217, 0.16);
    border-radius: 12px;
    color: #00B8D9;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.ItensCardLembretesNotificacaoCard .LembreteDataeHoraAtendimentoNotificacao{
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.Data_LembreteDataeHoraAtendimentoNotificacao{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: #637381;
}

.Data_LembreteDataeHoraAtendimentoNotificacao p{
    margin-left: 5px;
    font-size: 14px;
}

.Horario_LembreteDataeHoraAtendimentoNotificacao p{
    margin-left: 5px;
    font-size: 14px;
}

.Horario_LembreteDataeHoraAtendimentoNotificacao{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: #637381;
}








.CardPlantaoNotificacaoCard{
    background: #FFF5CC;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin: 15px 0;
}

.ItensCardPlantaoNotificacaoCard{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0;
}

.Titulo_CardPlantaoNotificacaoCard{
    font-size: 18px!important;
    font-family: Public Sans!important;
    font-weight: 700!important;
    color: #7A4100!important;
}

.Subtitulo_CardPlantaoNotificacaoCard{
    font-size: 14px!important;
    font-family: Public Sans!important;
    font-weight: 400!important;
    color: #7A4100!important;
}

.NotificacaoNomeCardNotificacaoCard{
    font-size: 14px!important;
    font-weight: 600!important;
    color: #7A4100!important;
}
.NotificacaoValorCardNotificacaoCard{
    font-size: 15px!important;
    font-weight: 600!important;
    color: #d70f20 !important;

}

.NotificacaoAssuntoCardNotificacaoCard{
    font-size: 12px!important;
    font-weight: 400!important;
    color: #7A4100!important;
}

.BtnIcon_CardNotificacaoCard{
    background-color: rgba(255, 171, 0, 0.16);
    border-radius: 12px;
    color: #FFAB00;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.BtnIcon_CardNotificacaoCardValor{
    background-color: rgba(255, 171, 0, 0.16);
    border-radius: 12px;
    color: #FFAB00;
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.PlantaoDataeHoraAtendimentoNotificacao{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.BotaoAtenderPlantao{
    background-color: #FFAB00!important;
    color: #fff!important;
    margin-right: 1%!important;
    width: 49%;
}

.BotaoNaoPossoAtenderPlantao{
    background-color: #FFD666!important;
    color: #B76E00!important;
    margin-left: 1%!important;
    width: 49%;
}





.MaterialApoioIndividualCard{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin: 0px;
}

.DataDoAtendimentoDashListagem{
    margin: 10px 0!important;
    font-size: 14px!important;
    font-weight: 600!important;
    color: #212B36!important;
}

.IconTituloeDataMaterialApoioIndividual{
    display: inline-flex;
    justify-content: center;
    align-items: center;
}



.TituloeDataMaterialdeApoio{
    text-align: left;
    margin-left: 10px;
}

.DataMaterialApoioIndividual{
    font-size: 12px!important;
    line-height: 18px!important;
    color: #919EAB!important;
}

.BotaoVerMaterialIndividual{
    margin-top: 15px!important;
}

.TituloMaterialApoioIndividual{
    font-size: 14px!important;
    font-weight: 600!important;
    color: #212B36!important;
}

.TituloAgendamentoCard{
    font-size: 18px!important;
    font-weight: 700!important;
    color: #212B36!important;
}

.SaldoAtualFinanceiroDashboard{
    font-size: 32px!important;
    font-family: Public Sans!important;
    font-weight: 700!important;
    line-height: 48px!important;
    color: #212B36!important;
}

.HeaderFinanceiroDashboard,.HeaderMateriaisApoioDashboard {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.IconTitleMateriaisApoioDashboard, .IconTitleAvaliacoesDashboard, .AvaliacaoIndividualCard{
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.IconMateriaisCircle{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: rgba(51, 102, 255, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
}

.IconMateriais{
    color: rgba(51, 102, 255, 1);
}

.FotoeNomeAvaliacaoIndividualCard{
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin: 5px 0;
}

.IconTitleAvaliacoesDashboard, .AvaliacaoIndividualCard{
    margin: 10px 0;
    width: 100%;
}

.OpcoesValorContribua{
    background: transparent!important;
}



.OpcaoValorContribua {
    color: #fff!important;
    background-color: transparent!important;
}

.OpcoesValorContribua button{
    border: 2px solid #fff!important;
}

.OpcoesValorContribua button.Mui-selected{
    background-color: #fff!important;
    color: #3366FF!important;
}

.OutroValorInputContribua fieldset{
    border-color: #fff!important;
}

.OutroValorInputContribuaTitulo{
    background-color: transparent!important;
    width: 95px;
}

.OutroValorInputContribua label, .OutroValorInputContribua input, .PrefixValorContribuicao p, .OutroValorInputContribuaTitulo{
    color: #fff!important;
}

.CardContribuaDashboard button.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButton-sizeMedium.MuiToggleButton-primary.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal.OpcaoValorContribua.css-2vaj6q-MuiButtonBase-root-MuiToggleButton-root {
    border: 1px solid #fff!important;
}

.PointData {
    display: inline-flex;
    align-items: center;
}

.PointAgendamento{
    height: 12px;
    width: 12px;
    border-radius: 12px;
    margin-right: 10px;
}

.PontoStatusRealizado{
    background-color: #00AB55;
}

.PontoStatusAgendado{
    background-color:#FFAB00;
}

.PontoStatusNaoConfirmado{
    background-color: #00B8D9;
}

.PontoStatusCancelado{
    background-color: #FF5630;
}

.StatusTextRealizado{
    color: #00AB55;
}

.StatusTextAgendado{
    color: #FFAB00;
}

.StatusTextNaoConfirmado{
    color: #00B8D9;
}

.StatusTextCancelado{
    color: #FF5630;
}

.DatasdeAgendamentos{
    font-size: 14px!important;
    font-weight: 600!important;   
}

.lineAgendamento{
    border-left: 1px solid rgba(145, 158, 171, 0.24);
    margin-left: 5px;
    margin-right: 5px;
}

.CardAgendamento{
    display: inline-flex;
    justify-content: space-between;
    width: 97%;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin: 15px;
}

.NomeeStatusAgendamento{
    margin-left: 10px;
}

.NomeAgendamento{
    font-size: 14px!important;
    font-weight: 600!important;
    color: #212B36!important;
}

.StatusAgendamento{
    font-size: 12px!important;
    line-height: 18px!important;
}

.FotoNomeeStatusAgendamento{
    display: inline-flex;
}


.SymbolCurrencyContribua p{
    color: #fff!important;
}

.AvaliacaoIndividualCard{
    margin: 15px 0;
    width: 100%;
}

.UltimasAvaliacoesTituloDashboard{
    margin: 15px 0!important;
}

.NomeDataAvaliacaoIndividual{
    margin-left: 10px;
}


.NomeDataAvaliacaoIndividual .NomeAvaliacaoIndividual{
    font-size: 14px!important;
    font-weight: 600!important;
    color: #212B36!important;
}

.NomeDataAvaliacaoIndividual .DataAvaliacaoIndividual{
    font-size: 12px!important;
    font-weight: 300!important;
    color: #919EAB!important;
}


.ResumoHeaderAvaliacoesDashboard{
    font-size: 16px!important;
    font-family: Public Sans!important;
    font-weight: 600!important;
    line-height: 24px;
    color: #637381!important;
    text-align: center;
}

.TotalAvaliacoesDashboard{
    font-size: 12px!important;
    font-family: Public Sans;
    line-height: 18px!important;
    color: #637381!important;
    text-align: center;
    margin-bottom: 10px!important;
}

.MediaAvaliacoesDashInicial{
    font-size: 32px!important;
    font-family: Public Sans;
    font-weight: 800!important;
    line-height: 42px!important;
    color: #212B36!important;
    margin-right: 10px!important;
}



.IndicadorNotificacaoMateriaisApoioDashInicial{
    border-radius: 50px!important;
    border: 1px solid  #00AB55!important;
    background: #00AB55!important;
    color: #fff!important;
    padding: 3px 8px;
}

.DetalhesFinanceiroDashboard{
    font-size: 14px!important;
    font-family: Public Sans;
    line-height: 22px;
    color: #637381!important;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin: 5px 0;
}

.ValorDisponivelparaSaque{
    font-size: 14px!important;
    font-family: Public Sans!important;
    font-weight: 600!important;
    line-height: 22px!important;
    color: #212B36!important;
}

.FiltroFinanceiroDashboard{
    width: 30%;
}

.BtnSolicitarSaque, .BtnVerMateriais, .BtnVerAvaliacoes{
    margin-top: 10px!important;
}

.CardTopoPrincipalDashFirst{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    margin-bottom: 15px!important;
}

.ItemsSobreMimCardTopoPrincipalDashFirstDesktop{
    margin: 10px 0!important;
}



.CarouselDashboardInicial{
    margin-bottom: 15px!important;
}



.FotoNomeDashFirst p{
    margin-left: 0px!important;
}

@media (min-width: 768x) and (max-width: 992px){
    .FotoNomeDashFirst{
        height: 12rem!important;
    }

    .FotoNomeDashFirst .NomeCardTopoPrincipalDashFirstDesktop{
        margin-left: 0px!important;
    }
}


.BotoesCardTopoPrincipalDashFirst{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
}

.BotoesCardTopoPrincipalDashFirstDesktop{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
}

.BuscaComoEstaSeSentindoDashInicialDesktop{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    height: 125px;
    justify-content: space-between;
    margin: 15px 0px;
}


.BuscaComoEstaSeSentindoDashInicialDesktopInputeBotao .btnAzul{
    background: #3366FF!important;
    border-radius: 8px!important;
    color: #fff!important;
    height: 40px;
    margin-right: 5px;
}

.BuscaComoEstaSeSentindoDashInicialDesktopInputeBotao .btnBorderAzul{
    background: #fff!important;
    border: 1px solid #3366FF!important;
    border-radius: 8px!important;
    color: #3366FF!important;
    height: 40px;
    margin-left: 5px;
}

.btnBuscarAnjoCardTopoPrincipalDashFirst,
.btnAdicionarSaldoCardTopoPrincipalDashFirst{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 5rem;
}

.TextoComplementarCardTopoPrincipalDashFirst{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #637381;
}

.ListadeAtendentesDashFirst .btnBorderAzul{
    border: 1px solid #3366FF!important;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #3366FF;
    margin: 8px 0px;
    width: 100%;
}

.BotoesCardTopoPrincipalDashFirst .btnBorderAzul{
    border: 1px solid #3366FF;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #3366FF;
    margin: 8px 0px;
    height: 6rem;
    width: 155px;
}

.BotoesCardTopoPrincipalDashFirstDesktop .btnBorderAzul{
    border: 1px solid #3366FF;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #3366FF;
    margin: 8px 0px;
    height: 6rem;
}

.CarrosselDashFirst{
    margin: 20px 0px;
    width: 100%;
    height: 10rem;
    background: #3366FF;
    border-radius: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.TituloInputBuscaporAnjoeFiltro{
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #637381;
}
.imgslider{
    width: 100%!important;
}


.MeusAgendamentosDashInicial .TituloMeusAgendamentosDashInicial{
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 22px;
    color: #212B36;
    margin: 10px 0;
}

.FinanceiroDashInicial .TituloFinanceiroDashInicial{
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 22px;
    color: #212B36;
    margin: 10px 0;
}

.InputBuscaporAnjoeFiltro .css-kn3umn-MuiInputBase-root-MuiOutlinedInput-root {
    width: 99%;
}

.FiltrarInputBuscaporAnjoeFiltro{
    cursor: pointer;
}

.BuscaComoEstaSeSentindoDashInicialDesktopInputeBotao{
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.CardMeusAgendamentosDashInicial, .SobreMimCardTopoPrincipalDashFirstDesktop{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin: 15px 0px;
}

.CardMeusAgendamentosDashInicial .ButtonCardMeusAgendamentosDashInicial{
    background-color: #3366FF!important;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #fff!important;
    margin: 8px 0px;
    width: 200px;
}

.TituloSobreMimCardTopoPrincipalDashFirstDesktop{
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 28px!important;
    color: #212B36!important;
}

.DescricaoSobreMimCardTopoPrincipalDashFirstDesktop{
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #212B36!important;
    margin: 20px 0!important;
}



.IconButtonCardMeusAgendamentosDashInicial{
    color: #fff;
}

.TituloFiltrarPorAssuntoDashFirst{
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 22px;
    color: #212B36;
    margin-bottom: 15px!important;
}

.MuiTabs-root.IconsFiltroBuscaAssunto.css-te4opc {
    height: 60px!important;
}

.CardAzulDashInicial{
    background: linear-gradient(135deg, #3366FF 0%, #3366FF 100%);
    border-radius: 16px;
    padding: 30px 40px;
    margin: 20px 0px;
}

.CardAzulDashInicial .TituloCardAzulDashInicial{
    color: #fff;
    font-style: normal;
    font-weight: 600!important;
    font-size: 24px!important;
    line-height: 32px!important;
    width: 60vw;
}

.TituloCardAzulDashInicialContribua{
    color: #fff;
    font-style: normal;
    font-weight: 600!important;
    font-size: 24px!important;
    line-height: 32px!important;
    text-align: center;
}

.CardAzulDashInicial .SubtituloCardAzulDashInicial{
    color: #fff;
    font-style: normal;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    margin: 13px 0;
}

.ContribuirValoreBotao{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.OutroValorContribua{
    margin-top: 10px;
}

.CardAzulDashInicial .SubtituloCardAzulDashInicialContribua{
    color: #fff;
    font-style: normal;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    margin: 13px 0;
    text-align: center;
}

.SubtituloCardAzulDashInicialContribua{
    color: #fff;
    font-style: normal;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    margin: 13px 0;
    text-align: center;
}

.SelecioneoValorContribua{
    font-size: 16px!important;
    font-weight: 600!important;
    text-align: center!important;
    color: #fff;
}

.BotaoContribuaPixCopiaeCola{
    background-color: #fff!important;
    color: #3366FF!important;
}

.CardAzulDashInicial .BtnCardAzulDashInicial{
    background: #FFFFFF;
    border-radius: 8px;
    color: #212B36;
    padding: 10.5px 14px;
    margin-left: 15px;
}



.FiltrarProfissionalDashInicial .CardFiltrarAtendente{
    margin: 15px 0px!important;
}

.FiltrarAssuntosDashInicial .CardFiltrarAssunto{
    margin: 0px!important;
}


.CardAzulDashInicial .ImagemCardAzulDashInicial{
    width: 160px;
    margin-bottom: 10px;
}

.ListadeAtendentesDashFirst .cardAtendente{
    margin:0px;
}

.ListadeAtendentesDashFirst .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1nky8vc .css-1cdo4bs > :not(style) + :not(style) {
    margin: 0px 0px 0px 0px;
    position: absolute;
    right: 40px;
}

.ListadeAtendentesDashFirst .cardAtendente span.MuiBadge-root.css-1rzb3uu {
    height: fit-content;
}

.ListadeAtendentesDashFirst .cardAtendente .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1nky8vc button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.BtnBrancoAtendente.css-19gizus {
    margin: 0;
    width: 80px;
}

.inputValorCardAzulDashInicial{
    background: rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    color: #fff!important;
}

.inputValorCardAzulDashInicial .css-h51211-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
    border-color: #fff;
}

.inputValorCardAzulDashInicial .css-k865uu-MuiFormLabel-root-MuiInputLabel-root,
.inputValorCardAzulDashInicial .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input{
    color: #fff;
}

.inputValorCardAzulDashInicial .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 10.5px 14px;
}







.FotoeNomeAtendenteDashFirstDesktop {
    display: inline-flex;
    align-items: center;
    margin-bottom: -20px;
    margin-left: 15px;
}

.DashFirstDesktop .CardMeusAgendamentosDashInicial{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
}

.MuiTabs-root.IconsFiltroBuscaAssunto.css-te4opc .css-1q0ig8p, .MuiTabs-root.IconsFiltroBuscaAssunto.css-te4opc .css-1q0ig8p.Mui-disabled{
    display: none;
}

@media (min-width: 768px) and (max-width: 1200px){
    .CardTopoPrincipalDashFirst{
        margin-bottom: 0px!important;
    }
}

@media (max-width: 767px){

    .CardTopoPrincipalDashFirst{
        margin-bottom: 0px!important;
    }

    .InputBuscaporAnjoeFiltro .css-kn3umn-MuiInputBase-root-MuiOutlinedInput-root {
        width: 97%;
    }

    .MuiFormControl-root.MuiTextField-root.inputValorCardAzulDashInicial.css-lavlqk-MuiFormControl-root-MuiTextField-root {
        width: 55%;
    }

}

@media (max-width: 450px){
    .inputValorCardAzulDashInicial{
        width: 50%;
    }

    .CardAzulDashInicial .BtnCardAzulDashInicial{
        margin-left: 3%;
        width: 30%;
    }
}
