.ItemChatAtendimento{
    display: flex!important;
    justify-content: space-between!important;
    background: #FFFFFF!important;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12)!important;
    border-radius: 16px!important;
    padding: 20px 15px!important;
    margin: 15px!important;
}

.TituloSeusAtendimentos{
    font-weight: 700!important;
    font-size: 20px!important;
    color: #212B36!important;
}
.divprogress{
    margin-top: 40px;
    width: 100%;
    text-align: center;
    align-items: center;
}