
.boxResumoListaPacote{
    background: #fff;
    border-radius: 8px;
    padding: 25px 30px!important;
    color: #637381;
    transition-duration: 0.5s;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12)!important;
    border: 1px solid rgba(145, 158, 171, 0.12);
}

.boxResumoListaPacote:hover .btn-remover{
    border: 1px solid #FFF;
    color: #FFF;
}

.boxResumoListaPacote:hover .css-1nky8vc {
    background: transparent;
}

.boxResumoListaPacote:hover{
    background: linear-gradient(135deg, #078dee, #0351ab);
    border-radius: 8px;
    padding: 25px 30px!important;
    color: #fff;
}

