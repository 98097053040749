.ItemChatAtendimento {
    display: flex!important;
    justify-content: space-between!important;
}

.NomeDataeFuncaoChatAtendimentoItem .NomeChatAtendimentoItem{
    font-size: 1rem;
    color: #1d1d1d;
    font-weight: 700;
}

.NomeDataeFuncaoChatAtendimentoItem .DataChatAtendimentoItem{
    font-size: .8rem;
    color: #3a3a3a;
    font-weight: 500;
}

.NomeDataeFuncaoChatAtendimentoItem .FuncaoChatAtendimentoItem{
    font-size: .8rem;
    color: #3a3a3a;
    font-weight: 500;
}

.ButtonsItemChatAtendimento {
    display: flex!important;
    flex-direction: row!important;
    align-items: center!important;
}

.FotoNomeDataeFuncaoChatAtendimentoItem{
    display: flex;
}

.ItemChatAtendimento  .BotaoMais_AtendentesHistoricoAcolhimentoRecorrente{
    width: 20px;
    height: 20px;
}