.CapaAtendenteCoverProfileDesktop{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    height: 250px;
}


.NomeCardTopoPrincipalCoverProfile{
    color: #fff;
    margin-top: 5px!important;
    font-weight: 700!important;
    font-size: 20px;
    height: 5rem;
}

.tabsCoverProfile{
    width: 100%;
    display: inline-flex;
    justify-content: end;
    padding-right: 20px;
    margin-top: -20px;
}

.FotoNomeCoverProfile, .FotoeNomeAtendenteCoverProfileDesktop{
    display: inline-flex;
    align-items: center;
    margin-left: 20px;
    margin-bottom: -15px;
}

.NomeCardTopoPrincipalCoverProfileDesktop{
    color: #fff;
    margin-top: 5px!important;
    font-weight: 700!important;
    font-size: 20px;
    margin-left: 15px!important;
}

.AtendenteDesdeCoverProfileDesktop{
    color: #ffffffbe;
    font-weight: 500!important;
    font-size: 12px!important;
    margin-left: 15px!important;
}



.ItemsSobreMimCardTopoPrincipalCoverProfileDesktop{
    margin: 15px 0!important;
}

.ItemsSobreMimCardTopoPrincipalCoverProfileDesktop p{
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #212B36!important;
    margin-left: 10px!important;
}

.CapaAtendenteCoverProfileDesktopAzul{
    border-radius: 16px 16px 0 0;
    background: linear-gradient(0deg, #3366ffc7, #3366ff2b), url(https://images.pexels.com/photos/16933563/pexels-photo-16933563/free-photo-of-fotografia-aerea-aerofotografia-tiro-com-drone-ilha.jpeg?auto=compress&cs=tinysrgb&w=1600);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 75%;
    display: inline-flex;
    align-items: flex-end;
}

.CardTopoPrincipalCoverProfile{
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    height: 12rem;
    border-radius: 16px 16px 0 0;
    background: linear-gradient(0deg, #3366ffc7, #3366ff2b), url(https://images.pexels.com/photos/16933563/pexels-photo-16933563/free-photo-of-fotografia-aerea-aerofotografia-tiro-com-drone-ilha.jpeg?auto=compress&cs=tinysrgb&w=1600);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



.TituloSobreMimCardTopoPrincipalCoverProfileDesktop{
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 28px!important;
    color: #212B36!important;
}

.DescricaoSobreMimCardTopoPrincipalCoverProfileDesktop{
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #212B36!important;
    margin: 20px 0!important;
}


.BotoesCardTopoPrincipalCoverProfile{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
}

.BotoesCardTopoPrincipalCoverProfileDesktop{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
}


.btnBuscarAnjoCardTopoPrincipalCoverProfile,
.btnAdicionarSaldoCardTopoPrincipalCoverProfile{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 5rem;
}

.TextoComplementarCardTopoPrincipalCoverProfile{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #637381;
}


.BotoesCardTopoPrincipalCoverProfile .btnBorderAzul{
    border: 1px solid #3366FF;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #3366FF;
    margin: 8px 0px;
    height: 6rem;
    width: 155px;
}

.BotoesCardTopoPrincipalCoverProfileDesktop .btnBorderAzul{
    border: 1px solid #3366FF;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #3366FF;
    margin: 8px 0px;
    height: 6rem;
}


.SobreMimCardTopoPrincipalCoverProfileDesktop{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin: 15px 0px;
}


.TituloSobreMimCardTopoPrincipalCoverProfileDesktop{
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 28px!important;
    color: #212B36!important;
}

.DescricaoSobreMimCardTopoPrincipalCoverProfileDesktop{
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #212B36!important;
    margin: 20px 0!important;
}

@media (min-width: 768x) and (max-width: 992px){
    .FotoNomeCoverProfile{
        height: 12rem!important;
    }

    .FotoNomeCoverProfile .NomeCardTopoPrincipalCoverProfileDesktop{
        margin-left: 0px!important;
    }
}

@media (max-width: 767px){
    .FotoNomeCoverProfile{
        display: inline-flex;
        align-items: center;
        margin: 0px!important;
        flex-direction: column;
        align-content: center;
    }

    .FotoNomeCoverProfile p{
        text-align: center;
        margin: 5px 0px!important;
    }

    .tabsCoverProfileMobile{
        border-radius: 0px 0px 16px 16px;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    }

    .CTAVerPerfilGrid, .CardFollowers {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        margin: 20px 0px!important;
    }

    .CTAVerPerfilGrid button {
        width: 90%!important;
    }

    .TopoCoverProfileMobile, .CardFollowers, .PerfilDoAtendenteCard, .RedesSociaisPerfilDoAtendenteCard, .InputPostAtendente {
        margin: 10px 24px!important;
    }
}
