.CardDivulgaNovaMensagemDashboard{
    background: linear-gradient(135deg, rgb(7, 141, 238), rgb(3, 81, 171));;
    border-radius: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 20px 40px;
}

.CardDivulgaNovaMensagemDashboard .TituloCardAzulDashInicialDivulgaNovaMensagem {
    color: #fff;
    font-size: 24px!important;
    font-style: normal;
    font-weight: 600!important;
    line-height: 32px!important;
    position: relative;
}

.CardDivulgaNovaMensagemDashboard .cardImgIconNovaMensagem{
    height: min-content;
}

@media (max-width: 767px) {
    .CardDivulgaNovaMensagemDashboard{
        background: #2065D1;
        border-radius: 16px;
        margin-top: 15px;
        margin-bottom: 10px;
        height: max-content;
    }
}