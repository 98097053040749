.MainCadastro {
    display: flex!important;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
}

.MainCadastroGoogle{
    display: flex!important;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
}

.heroCadasterBackground{
    background-image: url('https://api.eyhe.com.br/img/login_register/cadastro-bg.webp')!important;
    background-size: cover!important;
    background-position: center center!important;
    height: 100vh!important;
    margin: 0!important;
    padding: 0!important;
}

.ItensMainCadastro{
    width: 400px!important;
}

.ItensMainCadastroGoogle{
    width: 800px!important;
}

.Pais_Cadastro .ReactFlagsSelect-module_selectBtn__19wW7{
    height: 55px;
    padding: 16.5px 14px;
    border-radius: 8px;
    border: 1px solid rgba(145, 158, 171, 0.32);
}

.ItensMainCadastroGoogle .MuiFormControl-root.MuiFormControl-fullWidth.css-1w4vsez-MuiFormControl-root {
    margin: 5px 0;
}   

@media (max-width: 767px){
    .ItensMainCadastroGoogle{
        width: 90vw!important;
    }
}

.TopoCadastre{
    width: 100%;
    display: flex!important;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.LogoCadastre{
    width: 5rem;
    margin-bottom: 1.7rem;
}

.TituloCadastre{
    font-size: 1.5rem!important;
    font-weight: 700!important;
    color: #212B36!important;
}


.EmailCadastroButton {
    background-color: #078DEE!important;
    color: #fff!important;
    height: 3.8rem;
    width: 20rem!important;
}

.EmailCadastroButton:hover {
    background-color: #2298ed!important;
    color: #fff!important;
}

.AppleCadastroButton{
    background-color: #141414!important;
    color: #fff!important;
    height: 3.8rem;
    width: 20rem!important;
}

.AppleCadastroButton:hover{
    background-color: #212121!important;
    color: #fff!important;
}

.GoogleCadastroButton{
    border: 1px solid #078DEE!important;
    height: 3.8rem;
    width: 20rem!important;
}

.FazerLoginButton{
    border-bottom: 1px solid #078DEE!important;
    border-radius: 0px!important;
}

@media (max-width: 900px){
    .heroCadasterBackground{
        display: none!important;
    }

    .MainCadastroButtons {
        height: 80vh;
    }
}