.DialogDormir{
    background-image: url('../componentesDashboard/img/background.png');
    background-size: cover;
    color: #fff;
    border-radius: 12px!important;
    height: 420px;
    overflow: visible!important;
}

.DialogDormir .lua3d{
    position: relative;
    width: 120px;
    top: -60px;
    left: 80%;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper:has(.DialogDormir){
    overflow: visible!important;
    border-radius: 12px!important;
}

@media (max-width: 767px){
    .DialogDormir{
        height: auto;
    }

    .DialogDormir button{
        margin-bottom: 10px;
    }
    
}
@media (max-width: 350px){
    .textomodaldormir{
        font-size: 10px!important;
    }

    .titulomodaldormir{
        font-size: 14px!important;
    }



}