.detalhesAgendamentoGlobal{
    padding: 0 1rem;
}

.CardAgendamentoGeral {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    margin: 2rem 0!important;
}

.FotoeNomeCardAgendamento {
    display: flex;
    align-items: center;
}

.NomeeStatusCardAgendamento{
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: start!important;
    justify-content: flex-start!important;
}

.NomeeStatusCardAgendamento{
    margin-left: 1rem;
}

.NomeeStatusCardAgendamento .realizado {
    color: #36B37E;
}

.NomeeStatusCardAgendamento .agendado {
    color: #FFAB00;
}

.NomeeStatusCardAgendamento .naoConfirmado {
    color: #00B8D9;
}

.NomeeStatusCardAgendamento .cancelado {
    color: #FF5630;
}

.DialogInputHorarioAgendamento .MuiStack-root{
    width: 100%;
}

.calendarioCardAgendamento.realizado{
    background-color: rgba(54, 179, 126, 0.16);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendarioCardAgendamento.realizado svg{
    color: #36B37E;
}

.calendarioCardAgendamento.agendado{
    background-color: rgba(255, 171, 0, 0.16);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendarioCardAgendamento.agendado svg{
    color: #FFAB00;
}

.calendarioCardAgendamento.naoConfirmado{
    background-color: rgba(0, 184, 217, 0.16);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendarioCardAgendamento.naoConfirmado svg{
    color: #00B8D9;
}

.calendarioCardAgendamento.cancelado{
    background-color: rgba(255, 86, 48, 0.16);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendarioCardAgendamento.cancelado svg{
    color: #FF5630;
}


.CardInfoAtendimentoGeral,
.CardAvaliacaoInfoAgendamento, 
.RemarcarAtendimentoDialogGeral,
.CardRemarcarAtendimentoGeral{
    padding: 1.5rem;
    margin: 2rem 0px!important;
}

.CardAvaliacaoInfoAgendamento .AvaliacaoTexto,
.avisoRemarcarAtendimento{
    color: #979797!important;
    font-family: Public Sans!important;
    font-size: 14px!important;
    font-style: italic!important;
    font-weight: 400!important;
    line-height: 22px!important;
}

.avisoRemarcarAtendimento{
    text-align: center;
}

.dadosCardInfoAtendimento{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    color: #637381!important;
}

.CardRemarcarAtendimentoGeral input[type="date"],  .CardRemarcarAtendimentoGeral input[type="time"]{
    background-color: #0080ff;
    padding: 15px;
    font-family: "Roboto Mono",monospace;
    color: #ffffff;
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 5px;
    margin: 10px 0;
}
.CardRemarcarAtendimentoGeral ::-webkit-calendar-picker-indicator{
    background-color: #0080ff;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}

