.ItemChatAvaliacao {
    display: flex!important;
    justify-content: space-between!important;
    background: #FFFFFF!important;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12)!important;
    border-radius: 16px!important;
    padding: 20px 15px!important;
    margin: 15px 0px!important;
}

.NomeDataeFuncaoChatAvaliacaoItem .NomeChatAvaliacaoItem{
    font-size: 1rem;
    color: #1d1d1d;
    font-weight: 700;
}

.NomeDataeFuncaoChatAvaliacaoItem .DataChatAvaliacaoItem{
    font-size: .8rem;
    color: #3a3a3a;
    font-weight: 500;
}

.NomeDataeFuncaoChatAvaliacaoItem .FuncaoChatAvaliacaoItem{
    font-size: .8rem;
    color: #3a3a3a;
    font-weight: 500;
}

.ButtonsItemChatAvaliacao {
    display: flex!important;
    flex-direction: row!important;
    align-items: center!important;
}

.FotoNomeDataeFuncaoChatAvaliacaoItem{
    display: flex;
}

.ItemChatAvaliacao  .BotaoMais_AtendentesHistoricoAcolhimentoRecorrente{
    width: 20px;
    height: 20px;
}

.AvaliacaoEscrita{
    font-size: 0.9rem!important;
}