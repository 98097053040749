.degradeContribuaAnjo{
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin-bottom: 15px!important;
    background: linear-gradient(0deg,#3366ffc7,#3366ff2b),url('../../sections/@dashboard/Dashfirst/img/contribuicao-bg.jpg');
    background-position: 50%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.degradeContribuaPsicologo{
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin-bottom: 15px!important;
    background: linear-gradient(0deg,#7635dcc7,#7635dc2b),url('../../sections/@dashboard/Dashfirst/img/contribuicao-bg.jpg');
    background-position: 50%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.degradeContribuaPsicanalista{
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin-bottom: 15px!important;
    background: linear-gradient(0deg,#000,#000),url('../../sections/@dashboard/Dashfirst/img/contribuicao-bg.jpg');
    background-position: 50%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.degradeContribuaTerapeuta{
    box-shadow: 0px 0px 2px rgba(255, 190, 152, 0.2), 0px 12px 24px rgba(255, 190, 152, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin-bottom: 15px!important;
    background: linear-gradient(0deg,#ffbe98c7,#ffbe982b),url('../../sections/@dashboard/Dashfirst/img/contribuicao-bg.jpg');
    background-position: 50%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.degradeContribuaCoach{
    box-shadow: 0px 0px 2px rgba(93, 191, 192, 0.2), 0px 12px 24px rgba(93, 191, 192, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin-bottom: 15px!important;
    background: linear-gradient(0deg,#5dbfc0c7,#5dbfc02b),url('../../sections/@dashboard/Dashfirst/img/contribuicao-bg.jpg');
    background-position: 50%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.backgroundTopoAnjo{
    background-image: url('../../sections/@dashboard/Dashfirst/img/capa-anjo.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 9rem;
}

.backgroundTopoPsicologo{
    background-image: url('../../sections/@dashboard/Dashfirst/img/1.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 9rem;
}

.backgroundTopoPsicanalista{
    background-image: url('../../sections/@dashboard/Dashfirst/img/1.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 9rem;
}

.backgroundTopoTerapeuta{
    background-image: url('../../sections/@dashboard/Dashfirst/img/capa-terapeuta.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 9rem;
}

.backgroundTopoCoach{
    background-image: url('../../sections/@dashboard/Dashfirst/img/capa-coach.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 9rem;
}

.CapaAtendenteDashFirstDesktop{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    height: 25vh;
}

.CapaAtendenteDashFirstDeskAnjo{
    border-radius: 16px;
    background-image: url('../../sections/@dashboard/Dashfirst/img/capa-anjo.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: flex-end;
}

.CapaAtendenteDashFirstDeskPsicologo{
    border-radius: 16px;
    background-image: url('../../sections/@dashboard/Dashfirst/img/1.png')!important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: flex-end;
}

.CapaAtendenteDashFirstDeskPsicanalista{
    border-radius: 16px;
    background-image: url('../../sections/@dashboard/Dashfirst/img/1.png')!important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: flex-end;
}

.CapaAtendenteDashFirstDeskTerapeuta{
    border-radius: 16px;
    background-image: url('../../sections/@dashboard/Dashfirst/img/capa-terapeuta.png')!important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: flex-end;
}

.CapaAtendenteDashFirstDeskCoach{
    border-radius: 16px;
    background-image: url('../../sections/@dashboard/Dashfirst/img/capa-coach.png')!important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: flex-end;
}



@media (max-width: 767px){
.backgroundTopoAnjo{
    background-image: url('../../sections/@dashboard/Dashfirst/img/capa-anjo.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 9rem;
}
.backgroundTopoPsicologo{
    background-image: url('../../sections/@dashboard/Dashfirst/img/1.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 9rem;
}

.backgroundTopoPsicanalista{
    background-image: url('../../sections/@dashboard/Dashfirst/img/1.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 9rem;
}

.backgroundTopoTerapeuta{
    background-image: url('../../sections/@dashboard/Dashfirst/img/capa-terapeuta.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 9rem;
}

.backgroundTopoCoach{
    background-image: url('../../sections/@dashboard/Dashfirst/img/capa-coach.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 9rem;
}
}